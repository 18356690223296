* {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* twitter-linking */
#twitter-link-container * {
  font-family: Wix Madefor Display, 'Arial', 'Trebuchet MS', sans-serif;
  padding: 2;
}
#twitter-link-container > div {
  position: relative;
  border-radius: 15px;
  border: 3px solid #4a65b9;
  margin: 3px;
  overflow: visible;
}
#twitter-link-container > div::before {
  border-radius: 17px;
  content: ' ';
  position: absolute;
  z-index: -1;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  background: #4ab0ef;
}
